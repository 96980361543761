export default [
  {
    header: 'menu.billing',
  },
  {
    title: 'menu.budgets',
    route: 'drs-budgets',
    icon: 'FileTextIcon',
  },
  {
    title: 'menu.invoices',
    route: 'drs-invoices',
    icon: 'DollarSignIcon',
  },
  {
    title: 'menu.orders',
    route: 'drs-orders',
    icon: 'ClipboardIcon',
  },
]
