export default [
  {
    header: 'menu.customer',
  },
  {
    title: 'menu.agenda',
    route: 'drs-agenda',
    icon: 'ClockIcon',
  },
  {
    title: 'menu.customers',
    route: 'drs-customers',
    icon: 'UserIcon',
  },
]
