export default [
  {
    header: 'menu.user',
  },
  {
    title: 'menu.users_logs',
    route: 'drs-logs',
    icon: 'MonitorIcon',
  },
  {
    title: 'menu.finances',
    route: 'drs-finances',
    icon: 'ActivityIcon',
  },
  {
    title: 'menu.users',
    route: 'drs-users',
    icon: 'UsersIcon',
  },
]
