/* eslint-disable */
const actualUser = JSON.parse(window.localStorage.userData)
let productsMenu = null
switch (actualUser.role) {
  case 'ADMIN':
  productsMenu=[
  {
    header: 'menu.product',
  },
  {
    title: 'menu.products',
    route: 'drs-products',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'menu.stocks',
    route: 'drs-stocks',
    icon: 'PackageIcon',
  },
  {
    title: 'menu.categories',
    route: 'drs-categories',
    icon: 'LayersIcon',
  },
  {
    title: 'menu.brands',
    route: 'drs-brands',
    icon: 'AtSignIcon',
  },
  {
    title: 'menu.providers',
    route: 'drs-providers',
    icon: 'RadioIcon',
  }
]
break
case 'ADMINISTRATION':
productsMenu=[
  {
    header: 'menu.product',
  },
  {
    title: 'menu.products',
    route: 'drs-products',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'menu.stocks',
    route: 'drs-stocks',
    icon: 'PackageIcon',
  },
  {
    title: 'menu.categories',
    route: 'drs-categories',
    icon: 'LayersIcon',
  },
  {
    title: 'menu.brands',
    route: 'drs-brands',
    icon: 'AtSignIcon',
  },
  {
    title: 'menu.providers',
    route: 'drs-providers',
    icon: 'RadioIcon',
  }
]
break
case 'MECHANICS':
productsMenu=[
  {
    header: 'menu.product',
  },
  {
    title: 'menu.products',
    route: 'drs-products',
    icon: 'ShoppingBagIcon',
  },
]
break
}
export default productsMenu
