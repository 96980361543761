/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import productsMenu from './drs/products'
import customers from './drs/customers'
import users from './drs/users'
import billing from './drs/billing'
/* eslint-disable */

const actualUser = JSON.parse(window.localStorage.userData)
let menu = null

// Array of sections
switch (actualUser.role) {
  case 'ADMIN':
    menu = [...customers, ...billing, ...productsMenu, ...users]
    break
  case 'ADMINISTRATION':
    menu = [...customers, ...billing, ...productsMenu]
    break
  case 'MECHANICS':
    menu = [...customers, ...billing, ...productsMenu]
    break
  default:
    menu = []
    break
}

export default menu
